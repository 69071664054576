.SendError {
  margin-bottom: 1rem;
  white-space: pre-wrap;
}

.ButtonContainer {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  padding: 0 !important;
}