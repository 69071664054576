.root {
    flex-grow: 1;
}

.flex {
    flex: 1;
}

.menuButton {
    margin-left: -12px;
    margin-right: 20px;
}

.LogoContainer {
    height: 50px;
    margin: 0 16px 0 8px;
}