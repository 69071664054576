$header: #FFF;

$dark-gray: #b7b7b7;
$light-gray: #DDD;

$primary: #4398d8;
$success: #8fbb30;
$danger: #bb4c30;
$warning: #ECC043;
$default: $dark-gray;

$content: #FFF;

$background: #DDD;

$table-hover: #f5f5f5;

$input-disabled-bg: #e9ecef;

$text-color: #000;

$suek-green: #aed137;

$input-border: 1px solid $dark-gray;
$input-vertical-padding: .375rem;
$input-horizontal-padding: .25rem;
$input-line-height: 1.5;
$input-border-radius: 4px;

$gray-100: #eeeeee;
$gray-200: #fafafa;

@mixin heading {
  color: $primary;
  text-transform: uppercase;
  margin: 0 0 1rem 0;
}

@mixin remove-icon {
  position: absolute;
  top: -.25rem;
  right: -.5rem;
  color: #FFF;
  background: $primary;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 800px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 800px) { @content; }
}

@mixin for-tablet-landscape-down {
  @media (max-width: 1280px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 1280px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin for-big-desktop-down {
  @media (max-width: 1799px) { @content; }
}

@mixin for-print-only {
  @media print { @content; }
}
