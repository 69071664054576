.AddButton {
    position: fixed !important;
    bottom: 16px;
    right: 16px;
}

.Card {
    height: 100% !important;
}

.Table {
    min-width: 700px
}

.Paper {
    padding: 16px;
    width: 100%;
    overflow-x: auto;
}