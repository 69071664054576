.MaterialsEdit {
    padding: 16px;
}

.Button {
    margin: 16px 16px 0 0 !important;
}

.TextField {
    width: 100%;
}

.LinksHeader {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    margin-bottom: 0;
}

.LinkItem {
    display: flex;
    align-items: flex-end;
}

.DeleteIcon {
    cursor: pointer;
    margin-bottom: 0.33rem;
    color: rgba(0, 0, 0, 0.54);
}

.AddIcon {
    cursor: pointer;
}

.Dropzone {
    margin: 16px 0;
    width: 100%;
    height: 150px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.42);
    border-style: dashed;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
