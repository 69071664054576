body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #aed137 !important;
}


:global(.MuiFormLabel-root.Mui-disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}
/*
:global(.Mui-disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}
*/
