.Paper {
    padding: 1rem !important;
    margin: 1rem 0;
}


.Dropzone {
    cursor: pointer;
    height: 4rem;
    border: 3px dotted #BBB;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    margin: .5rem 0;
    transition: height 300ms ease-in-out;
}

.DropzoneDragActive {
    height: 6rem;
    border: 3px dotted #aed137;
}